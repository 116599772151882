import * as React from 'react'

const EdynLogo = () => (
    <svg width="114" height="30" viewBox="0 0 114 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M43.1646 10.9468C43.7203 11.2111 44.1997 11.5683 44.5895 12.0089C44.9786 12.4491 45.2893 12.9721 45.5124 13.5627C45.7366 14.1536 45.8504 14.7795 45.8504 15.4232C45.8504 15.5966 45.8384 15.8115 45.815 16.0613L45.8028 16.1944H38.8838C39.0187 16.794 39.3072 17.2993 39.7423 17.6983C40.2182 18.1348 40.8002 18.356 41.4739 18.356C41.9225 18.356 42.3552 18.2513 42.7596 18.0446C43.1611 17.8393 43.4911 17.5485 43.7382 17.1801L43.8215 17.056L45.2645 18.2309L45.1871 18.347C44.7792 18.9599 44.2418 19.4468 43.5889 19.7942C42.9376 20.1413 42.2257 20.3172 41.4739 20.3172C40.201 20.3172 39.1053 19.8423 38.2172 18.9064C37.3294 17.9703 36.8789 16.8046 36.8789 15.4419C36.8789 14.5764 37.0697 13.7623 37.4461 13.0215C37.8226 12.2791 38.3629 11.6748 39.0506 11.2258C39.7403 10.7758 40.5204 10.5479 41.3692 10.5479C42.0032 10.5479 42.6074 10.6821 43.1646 10.9468ZM39.8019 13.1076C39.4019 13.4621 39.1187 13.915 38.9573 14.4564H43.7937C43.674 13.9309 43.4092 13.4823 43.0052 13.1211C42.5588 12.7213 42.014 12.5183 41.3865 12.5183C40.7758 12.5183 40.2432 12.7166 39.8019 13.1076Z" fill="#0F2A2B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M51.3973 10.5475C52.479 10.5475 53.3891 10.9204 54.1075 11.6571V5.66162H56.0971V20.0655H54.1075V19.2218C53.4064 19.9488 52.4962 20.3169 51.3973 20.3169C50.7584 20.3169 50.1467 20.1892 49.579 19.9377C49.012 19.6867 48.5166 19.3414 48.1057 18.911C47.6964 18.481 47.3672 17.9582 47.1275 17.3559C46.8887 16.7537 46.7676 16.1098 46.7676 15.4416C46.7676 14.7661 46.8903 14.1171 47.1323 13.5128C47.3744 12.9079 47.7068 12.383 48.1197 11.9528C48.5329 11.5224 49.0295 11.1771 49.5969 10.9263C50.1642 10.6749 50.7698 10.5475 51.3973 10.5475ZM49.5036 17.4782C50.0104 18.0237 50.647 18.3 51.3973 18.3C52.1585 18.3 52.801 18.0192 53.3078 17.4651C53.8151 16.9098 54.0723 16.2292 54.0723 15.4416C54.0723 14.6539 53.8124 13.9716 53.2991 13.4135C52.787 12.8565 52.1471 12.5739 51.3973 12.5739C50.6367 12.5739 50.0173 12.8455 49.5042 13.4041C48.9968 13.9563 48.7397 14.6416 48.7397 15.4416C48.7397 16.2473 48.9968 16.9326 49.5036 17.4782Z" fill="#0F2A2B" />
    <path d="M61.5011 17.24L59.2051 10.8104L59.1715 10.7153H56.9834L60.518 20.089L59.2104 24.0243H61.1996L65.7136 10.7153H63.6972L61.5011 17.24Z" fill="#0F2A2B" />
    <path d="M71.0553 10.5479C70.4723 10.5479 69.9732 10.6513 69.5715 10.8554C69.2683 11.0093 68.9836 11.2187 68.7215 11.4789V10.7152H66.7314V20.066H68.7215V15.3021C68.7215 13.4664 69.3845 12.5742 70.7495 12.5742C72.0559 12.5742 72.6909 13.4242 72.6909 15.1721V20.066H74.6984V14.7905C74.6984 13.3913 74.3625 12.3227 73.7007 11.615C73.039 10.9068 72.149 10.5479 71.0553 10.5479Z" fill="#0F2A2B" />
    <path d="M85.5529 16.9489C85.321 17.3656 85.0057 17.6985 84.6153 17.9387C84.2243 18.1787 83.7864 18.3005 83.3127 18.3005C82.5743 18.3005 81.9403 18.0193 81.4276 17.465C80.9149 16.9101 80.655 16.2356 80.655 15.4605C80.655 14.6844 80.9126 14.0013 81.4211 13.4309C81.9342 12.8546 82.5413 12.5742 83.2776 12.5742C83.735 12.5742 84.1664 12.6946 84.5594 12.9317C84.9542 13.1701 85.2712 13.4899 85.5021 13.8823L85.573 14.0025L87.2643 12.9709L87.1867 12.8407C86.7748 12.1468 86.2156 11.5852 85.5259 11.1719C84.8352 10.7579 84.0786 10.5479 83.2776 10.5479C82.4406 10.5479 81.6604 10.7707 80.9571 11.2107C80.2544 11.6501 79.694 12.2505 79.2908 12.9948C78.8875 13.7401 78.6826 14.5636 78.6826 15.4419C78.6826 16.3209 78.8907 17.1444 79.3 17.8897C79.7092 18.6342 80.2729 19.2317 80.9756 19.6644C81.6783 20.0976 82.4529 20.3172 83.2776 20.3172C84.0902 20.3172 84.8555 20.1058 85.5519 19.6887C86.2475 19.2723 86.8095 18.7025 87.2225 17.9956L87.2978 17.8668L85.6228 16.8225L85.5529 16.9489Z" fill="#0F2A2B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M92.6543 10.5479C93.736 10.5479 94.646 10.9207 95.3645 11.6574V10.7152H97.3539V20.0658H95.3645V19.2221C94.6634 19.9491 93.7533 20.3172 92.6543 20.3172C92.0154 20.3172 91.4038 20.1895 90.8358 19.9381C90.2691 19.687 89.7736 19.3417 89.3627 18.9113C88.9533 18.4813 88.6243 17.9585 88.3843 17.3562C88.1457 16.754 88.0244 16.1101 88.0244 15.4419C88.0244 14.7664 88.1472 14.1174 88.3894 13.5132C88.6312 12.9083 88.9636 12.3834 89.3768 11.9532C89.7897 11.5227 90.2863 11.1774 90.8537 10.9266C91.421 10.6752 92.0268 10.5479 92.6543 10.5479ZM90.7606 17.4785C91.2672 18.024 91.9041 18.3003 92.6543 18.3003C93.4155 18.3003 94.0581 18.0195 94.5647 17.4654C95.0721 16.9101 95.3292 16.2295 95.3292 15.4419C95.3292 14.6542 95.0695 13.9719 94.5561 13.4138C94.0441 12.8568 93.404 12.5742 92.6543 12.5742C91.8937 12.5742 91.2743 12.8458 90.7612 13.4044C90.2538 13.9566 89.9968 14.6419 89.9968 15.4419C89.9968 16.2476 90.2538 16.933 90.7606 17.4785Z" fill="#0F2A2B" />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="104" y="10" width="10" height="11">
      <path fillRule="evenodd" clipRule="evenodd" d="M104.956 10.5479H113.927V20.3172H104.956V10.5479Z" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M112.667 12.0089C113.056 12.4491 113.366 12.9721 113.59 13.5627C113.814 14.1536 113.928 14.7795 113.928 15.4232C113.928 15.5966 113.916 15.8115 113.892 16.0613L113.88 16.1944H106.961C107.096 16.794 107.384 17.2993 107.819 17.6983C108.295 18.1348 108.877 18.356 109.551 18.356C110 18.356 110.432 18.2513 110.837 18.0446C111.238 17.8393 111.568 17.5485 111.815 17.1801L111.899 17.056L113.342 18.2309L113.264 18.347C112.856 18.9599 112.319 19.4468 111.666 19.7942C111.015 20.1413 110.303 20.3172 109.551 20.3172C108.278 20.3172 107.182 19.8423 106.294 18.9064C105.407 17.9703 104.956 16.8046 104.956 15.4419C104.956 14.5764 105.147 13.7623 105.523 13.0215C105.9 12.2791 106.44 11.6748 107.128 11.2258C107.817 10.7758 108.597 10.5479 109.446 10.5479C110.08 10.5479 110.685 10.6821 111.241 10.9468C111.797 11.2111 112.277 11.5683 112.667 12.0089ZM107.879 13.1076C107.479 13.4621 107.196 13.915 107.034 14.4564H111.871C111.751 13.9309 111.486 13.4823 111.082 13.1211C110.636 12.7213 110.091 12.5183 109.464 12.5183C108.853 12.5183 108.32 12.7166 107.879 13.1076Z" fill="#0F2A2B" />
    </g>
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask1)">
      <path d="M98.9805 20.0659H100.971V10.7153H98.9805V20.0659Z" fill="#0F2A2B" />
    </g>
    <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask2)">
      <path d="M102.825 10.8782C102.035 11.1786 101.47 11.8735 101.192 12.74C101.923 13.2231 102.773 13.3796 103.563 13.0794C104.353 12.7788 104.918 12.0839 105.196 11.2176C104.466 10.7343 103.615 10.5778 102.825 10.8782Z" fill="#0F2A2B" />
    </g>
    <mask id="mask3" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask3)">
      <path d="M17.2862 10.5107C16.0312 10.0691 14.701 10.356 13.6396 11.1622C14.041 12.4834 14.9489 13.5579 16.2039 13.9995C17.4588 14.4411 18.7892 14.1542 19.8505 13.3481C19.449 12.0271 18.5412 10.9524 17.2862 10.5107Z" fill="#0F2A2B" />
    </g>
    <mask id="mask4" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask4)">
      <path d="M8.91097 18.1971C9.23021 19.563 10.1682 20.6079 11.3751 21.1335C12.2094 20.0667 12.5781 18.6755 12.2588 17.3094C11.9394 15.9437 11.0014 14.8988 9.79472 14.373C8.96014 15.44 8.59173 16.8312 8.91097 18.1971Z" fill="#0F2A2B" />
    </g>
    <mask id="mask5" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask5)">
      <path d="M4.33872 4.2298C3.43546 5.25735 3.1213 6.66368 3.3623 8.02817C4.65674 8.1949 5.95362 7.76878 6.85708 6.74123C7.76035 5.71368 8.07451 4.30735 7.8333 2.94286C6.53906 2.77613 5.24219 3.20225 4.33872 4.2298Z" fill="#0F2A2B" />
    </g>
    <mask id="mask6" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask6)">
      <path d="M21.2416 5.51912C20.5304 6.70545 20.4636 8.14933 20.9354 9.44361C22.2366 9.35484 23.4383 8.6828 24.1496 7.49627C24.8608 6.30994 24.9274 4.86627 24.4556 3.57178C23.1544 3.66055 21.9529 4.3328 21.2416 5.51912Z" fill="#0F2A2B" />
    </g>
    <mask id="mask7" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask7)">
      <path d="M17.5323 0.454093C16.3568 -0.189173 15.0008 -0.125295 13.835 0.495929C14.0394 1.86736 14.7799 3.07879 15.9552 3.72226C17.1308 4.36552 18.4868 4.30164 19.653 3.68042C19.4484 2.30899 18.7077 1.09756 17.5323 0.454093Z" fill="#0F2A2B" />
    </g>
    <mask id="mask8" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask8)">
      <path d="M12.7959 9.29569C13.8404 8.43324 14.3592 7.09752 14.3239 5.70956C13.0707 5.32691 11.7267 5.52936 10.6822 6.39181C9.63767 7.25426 9.11868 8.58997 9.15404 9.97814C10.4074 10.3606 11.7512 10.1581 12.7959 9.29569Z" fill="#0F2A2B" />
    </g>
    <mask id="mask9" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask9)">
      <path d="M2.60985 23.3638C3.32718 24.5458 4.53241 25.2109 5.83396 25.2919C6.2991 23.9948 6.22473 22.5513 5.5072 21.3693C4.78987 20.187 3.58464 19.5221 2.28309 19.4409C1.81815 20.7383 1.89232 22.1815 2.60985 23.3638Z" fill="#0F2A2B" />
    </g>
    <mask id="mask10" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask10)">
      <path d="M1.76101 11.9041C0.662054 12.6861 0.0562888 13.9796 0 15.3669C1.22514 15.8424 2.57892 15.741 3.67787 14.959C4.77682 14.1769 5.38259 12.8832 5.43888 11.4961C4.21373 11.0206 2.85996 11.1218 1.76101 11.9041Z" fill="#0F2A2B" />
    </g>
    <mask id="mask11" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask11)">
      <path d="M12.0789 29.5978C13.3065 30.1196 14.6505 29.9188 15.7562 29.1826C15.4302 27.8382 14.5853 26.7069 13.3579 26.1851C12.1303 25.6635 10.7863 25.8641 9.68066 26.6002C10.0066 27.9447 10.8516 29.0759 12.0789 29.5978Z" fill="#0F2A2B" />
    </g>
    <mask id="mask12" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask12)">
      <path d="M25.5916 10.6049C24.3338 11.0361 23.4181 12.1032 23.0068 13.421C24.0627 14.2359 25.3907 14.5338 26.6487 14.1026C27.907 13.6712 28.8225 12.604 29.2338 11.2863C28.1781 10.4716 26.8497 10.1736 25.5916 10.6049Z" fill="#0F2A2B" />
    </g>
    <mask id="mask13" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask13)">
      <path d="M24.4033 20.6032C24.8754 21.9178 25.9252 22.8344 27.1828 23.2007C27.8898 22.0342 28.0979 20.6056 27.6256 19.2909C27.1538 17.976 26.104 17.0597 24.8465 16.6934C24.1392 17.8599 23.9313 19.2885 24.4033 20.6032Z" fill="#0F2A2B" />
    </g>
    <mask id="mask14" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask14)">
      <path d="M18.842 21.9018C20.1049 21.4867 21.0324 20.4314 21.4587 19.1192C20.4124 18.2908 19.0877 17.9757 17.8247 18.3908C16.5618 18.8059 15.6341 19.861 15.208 21.1735C16.2545 22.0018 17.579 22.3169 18.842 21.9018Z" fill="#0F2A2B" />
    </g>
    <mask id="mask15" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask15)">
      <path d="M22.1375 27.7941C23.1367 26.8726 23.587 25.5092 23.4811 24.1251C22.21 23.8153 20.8786 24.0949 19.8794 25.0163C18.8803 25.9375 18.4298 27.3012 18.5356 28.6853C19.8065 28.9949 21.1383 28.7155 22.1375 27.7941Z" fill="#0F2A2B" />
    </g>
    <mask id="mask16" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask16)">
      <path d="M18.1068 7.75636C18.1068 8.31758 17.6794 8.77268 17.1525 8.77268C16.6256 8.77268 16.1982 8.31758 16.1982 7.75636C16.1982 7.19513 16.6256 6.74023 17.1525 6.74023C17.6794 6.74023 18.1068 7.19513 18.1068 7.75636Z" fill="#EB5D48" />
    </g>
    <mask id="mask17" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask17)">
      <path d="M12.1908 3.28488C12.1908 3.84611 11.7634 4.301 11.2365 4.301C10.7096 4.301 10.2822 3.84611 10.2822 3.28488C10.2822 2.72366 10.7096 2.26855 11.2365 2.26855C11.7634 2.26855 12.1908 2.72366 12.1908 3.28488Z" fill="#EB5D48" />
    </g>
    <mask id="mask18" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask18)">
      <path d="M16.1029 16.6997C16.1029 17.2609 15.6755 17.716 15.1488 17.716C14.6217 17.716 14.1943 17.2609 14.1943 16.6997C14.1943 16.1385 14.6217 15.6836 15.1488 15.6836C15.6755 15.6836 16.1029 16.1385 16.1029 16.6997Z" fill="#EB5D48" />
    </g>
    <mask id="mask19" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask19)">
      <path d="M9.50619 12.3706C9.50619 12.9318 9.07884 13.3869 8.55192 13.3869C8.025 13.3869 7.59766 12.9318 7.59766 12.3706C7.59766 11.8094 8.025 11.3545 8.55192 11.3545C9.07884 11.3545 9.50619 11.8094 9.50619 12.3706Z" fill="#EB5D48" />
    </g>
    <mask id="mask20" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask20)">
      <path d="M6.46518 17.4111C6.46518 17.9724 6.03783 18.4275 5.51091 18.4275C4.98399 18.4275 4.55664 17.9724 4.55664 17.4111C4.55664 16.8499 4.98399 16.395 5.51091 16.395C6.03783 16.395 6.46518 16.8499 6.46518 17.4111Z" fill="#EB5D48" />
    </g>
    <mask id="mask21" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask21)">
      <path d="M22.7484 15.8864C22.7484 16.4477 22.321 16.9026 21.7941 16.9026C21.2672 16.9026 20.8398 16.4477 20.8398 15.8864C20.8398 15.3252 21.2672 14.8701 21.7941 14.8701C22.321 14.8701 22.7484 15.3252 22.7484 15.8864Z" fill="#EB5D48" />
    </g>
    <mask id="mask22" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask22)">
      <path d="M29.6556 15.6833C29.6556 16.2445 29.2283 16.6994 28.7013 16.6994C28.1742 16.6994 27.7471 16.2445 27.7471 15.6833C27.7471 15.1221 28.1742 14.667 28.7013 14.667C29.2283 14.667 29.6556 15.1221 29.6556 15.6833Z" fill="#EB5D48" />
    </g>
    <mask id="mask23" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask23)">
      <path d="M17.2474 24.5249C17.2474 25.0861 16.8201 25.5412 16.2933 25.5412C15.7662 25.5412 15.3389 25.0861 15.3389 24.5249C15.3389 23.9637 15.7662 23.5088 16.2933 23.5088C16.8201 23.5088 17.2474 23.9637 17.2474 24.5249Z" fill="#EB5D48" />
    </g>
    <mask id="mask24" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="30">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.89H113.928V0H0V29.89Z" fill="white" />
    </mask>
    <g mask="url(#mask24)">
      <path d="M10.4728 23.407C10.4728 23.9682 10.0456 24.4231 9.51872 24.4231C8.9916 24.4231 8.56445 23.9682 8.56445 23.407C8.56445 22.8457 8.9916 22.3906 9.51872 22.3906C10.0456 22.3906 10.4728 22.8457 10.4728 23.407Z" fill="#EB5D48" />
    </g>
  </svg>
)

export default EdynLogo